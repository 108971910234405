@import 'variables/colors';

.rcw-previewer-container {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;

  .rcw-previewer-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: all 0.3s ease;
  }

  .rcw-previewer-tools {
    position: fixed;
    right: 16px;
    bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .rcw-previewer-button {
    padding: 0;
    margin: 16px;
    box-shadow: 0 3px 8px 0px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    background-color: $white;
    border: none;
  }

  .rcw-previewer-close-button {
    position: absolute;
    right: 0;
    top: 0;
  }

  .rcw-previewer-veil {
    width: 100%;
    height: 100%;
    overflow: scroll;
    position: relative;
  }
}
