:root {
  --container-color: #090C29;
  --bg-color: #161c54;
  --fg-color: #35395e;
  --text-bar: #0d1136;
  --sender-message: #9fe5f4;
  --response-message: #fff;
}

.deephealth {
  --container-color: #141414;
  --bg-color: #212121;
  --fg-color: #303235;
  --text-bar: #303235;
  --sender-message: #9fe5f4;
  --response-message: #fff;
}

.chatSidebar > .rcw-widget-container {
  position: relative;
  border-radius: 0px;
  width:100%;
  margin-bottom:0px;
  flex: 1 1 auto;
}
.chatSidebar .rcw-conversation-container {
  background: var(--container-color);
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 0px;
  height: 100%;
}
.chatSidebar .rcw-conversation-container ol {
  list-style: auto;
  margin: auto;
  padding: 0.5em 1em;
}
.chatSidebar .rcw-picker-btn {
  display: none;
}
.chatSidebar .rcw-conversation-container .rcw-header {
  background: var(--bg-color);
  border-radius: 0px;
  font-size: 16px;
  padding:0px;
}
.chatSidebar .rcw-conversation-container .rcw-header h4 {
  font-size: 16px;
  padding:8px;
}
.chatSidebar .rcw-timestamp {
  color:#FFF;
}
.chatSidebar .rcw-conversation-container .rcw-sender {
  background: var(--bg-color);
  border-radius: 0px;
  display: none;
}

.chatSidebar .rcw-message-text {
  max-width: 90vw;
}

/* .chatSidebar .rcw-response .rcw-message-text {
  background: var(--response-message);
}

.chatSidebar .rcw-client .rcw-message-text {
  background: var(--sender-message);
} */

.chatSidebar .rcw-messages-container {
  background: var(--container-color);
  height: auto;
  max-height: 70vh;
}
.chatSidebar {
  display: flex;
  flex-flow: column;
  height:100%;
}

.inputPanel{
  height: 165px;
  position: fixed;
  bottom: 0;
  width: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {

  background: var(--container-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--fg-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--fg-color);
}

.chatSidebar .rcw-launcher {
  display:none
}
.chatSidebar .rcw-picker-btn, .rcw-send {
  background: none;
  display: none;
}
.chatSidebar .rcw-new-message {
  background-color: var(--fg-color);
  color:#FFF;
  display: none;

}
.chatSidebar .sendPanel {
  padding: .5em 1em;
  background: var(--bg-color);
  display: block;
  flex: 0 1 64px;
  height: 40%;
}
.chatSidebar .sendPanel button{
  width:10%;
  float:left;
  margin: 0%;
  border-radius: 0px 5px 5px 0px;
  background: var(--bg-color);
  text-align: center;
  padding:.5em;
  border: 1px solid #444444;
  border-radius: 6px;
  cursor:pointer
}
.chatSidebar .sendPanel button.micButton {
  border-radius: 5px 0px 0px 5px;
}
.chatSidebar .sendPanel button.recording {
  background-color: rgb(192, 23, 25);
}

.chatSidebar .sendPanel button.sendButton {
  border-radius: 0px 5px 5px 0px;
}

.chatSidebar .sendPanel button div svg{
  margin-left: auto;
  margin-right:auto;
}
.chatSidebar .disclaimer {
  text-align: center;
  color:#FFF;
  background-color: var(--container-color);
  font-size: .9em;
  padding: 1em;
  display: block;
  clear: both;
  flex: 0 1 auto;
}
.chatSidebar .speechToTextMode .rcw-new-message {
  display: none;
}
.chatSidebar .sendPanel .textEntryComponent:focus{
  outline: none;
  border:1px solid #444444
}
.chatSidebar .sendPanel .textEntryComponent {
  background: var(--text-bar);
  border:1px solid #444444;
  border-left:none;
  border-right: none;
  float:left;
  border-radius: 0px;
  padding: 10px 5px;
  resize: none;
  width: calc(77%);
  height:48px;
  color:#FFFFFF;
}
.arbitrary {
  color: #FFF
}

.chatSidebar .loadingContainer {
  width: 100%;
  height: 64px;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg-color);

}

@media screen and (max-width: 425px)  {
  .chatSidebar .inputPanel {
    font-size: 0.75rem;
    position: relative;
  }
  .chatSidebar .sendPanel {
    height: 9vh;
  }
  .chatSidebar .sendPanel .textEntryComponent{
    height: 44px;
  }
}
